import { useState, useContext, useEffect } from 'react'
import { updateSettings } from '../services/api'
import { Context } from '../utils/context'
import {
    AccountProviderScraperIF,
    DropletStatus,
    ScraperStatusIF,
} from '../utils/types'
import styles from './Settings.module.css'
import Loader from 'react-spinners/BounceLoader'
import { formatTeam } from '../utils/bets'

const StatusDetails = {
    ACTIVE: { value: 'ACTIVE', priority: 1 },
    STARTING: { value: 'STARTING', priority: 2 },
    STOPPING: { value: 'STOPPING', priority: 3 },
    WARNING: { value: 'WARNING', priority: 4 },
    INACTIVE: { value: 'INACTIVE', priority: 5 },
}

export const Settings = () => {
    const { games, accounts, settings } = useContext(Context)
    const [isLoading, setIsLoading] = useState(!settings)
    const accountProviders = accounts.flatMap(
        (a) =>
            a.accountProviders.map((ap) => ({
                ...ap,
                account: a,
            })) as AccountProviderScraperIF[]
    )

    useEffect(() => {
        if (settings) setIsLoading(false)
    }, [settings])

    const toggleScrapers = async () => {
        if (settings) {
            setIsLoading(true)
            await updateSettings({
                scrapersActive: !settings.scrapersActive,
            })
            setIsLoading(false)
        }
    }

    const toggleBettors = async () => {
        if (settings) {
            setIsLoading(true)
            await updateSettings({
                bettorsActive: !settings.bettorsActive,
            })
            setIsLoading(false)
        }
    }

    const getScraperStatus = (scraper: ScraperStatusIF) =>
        scraper.healthchecksMissed > 0 &&
        scraper.dropletStatus === DropletStatus.ACTIVE
            ? StatusDetails.WARNING
            : scraper.dropletStatus === DropletStatus.ACTIVE ||
              scraper.dropletStatus === DropletStatus.STARTING ||
              scraper.dropletStatus === DropletStatus.STOPPING
            ? StatusDetails[scraper.dropletStatus]
            : StatusDetails.INACTIVE

    return (
        <div className={styles.settings}>
            <header>Settings</header>
            <div className={styles.section}>
                <div className={styles.section_header}>
                    Scraper Management
                    {settings && (
                        <button
                            className={`${
                                settings.scrapersActive && styles.danger
                            }`}
                            onMouseDown={toggleScrapers}
                            disabled={isLoading}
                        >
                            Turn {settings.scrapersActive ? 'Off' : 'On'}{' '}
                            Scrapers
                        </button>
                    )}
                </div>
                <div className={styles.section_content}>
                    {games.length > 0 ? (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <td>Status</td>
                                    <td>Game</td>
                                    <td>Provider</td>
                                </tr>
                            </thead>
                            <tbody>
                                {games
                                    .sort(
                                        (a, b) =>
                                            getScraperStatus(a).priority -
                                            getScraperStatus(b).priority
                                    )
                                    .map((g) => {
                                        const status = getScraperStatus(g).value
                                        return (
                                            <tr key={g.id}>
                                                <td>
                                                    <div
                                                        className={
                                                            styles.game_status
                                                        }
                                                    >
                                                        <div
                                                            className={`${
                                                                styles.status
                                                            } ${
                                                                status ===
                                                                StatusDetails
                                                                    .ACTIVE
                                                                    .value
                                                                    ? styles.success
                                                                    : status ===
                                                                      StatusDetails
                                                                          .INACTIVE
                                                                          .value
                                                                    ? styles.danger
                                                                    : styles.warn
                                                            }`}
                                                        />
                                                        {status}
                                                    </div>
                                                </td>
                                                <td>
                                                    {formatTeam(
                                                        g.awayTeam,
                                                        g.homeTeam
                                                    )}
                                                </td>
                                                <td>{g.provider.name}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    ) : isLoading ? (
                        <div className={styles.loading}>
                            <Loader
                                loading={isLoading}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        </div>
                    ) : (
                        <p>No scrapers active.</p>
                    )}
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.section_header}>
                    Bettor Management
                    {settings && (
                        <button
                            className={`${
                                settings.bettorsActive && styles.danger
                            }`}
                            onMouseDown={toggleBettors}
                            disabled={isLoading}
                        >
                            Turn {settings.bettorsActive ? 'Off' : 'On'} Bettors
                        </button>
                    )}
                </div>
                <div className={styles.section_content}>
                    {accountProviders.length > 0 ? (
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <td>Status</td>
                                    <td>Site</td>
                                    <td>Username</td>
                                    <td>Provider</td>
                                </tr>
                            </thead>
                            <tbody>
                                {accountProviders
                                    .sort(
                                        (a, b) =>
                                            getScraperStatus(a).priority -
                                            getScraperStatus(b).priority
                                    )
                                    .map((a) => {
                                        const status = getScraperStatus(a).value
                                        return (
                                            <tr key={a.id}>
                                                <td>
                                                    <div
                                                        className={
                                                            styles.game_status
                                                        }
                                                    >
                                                        <div
                                                            className={`${
                                                                styles.status
                                                            } ${
                                                                status ===
                                                                StatusDetails
                                                                    .ACTIVE
                                                                    .value
                                                                    ? styles.success
                                                                    : status ===
                                                                      StatusDetails
                                                                          .INACTIVE
                                                                          .value
                                                                    ? styles.danger
                                                                    : styles.warn
                                                            }`}
                                                        />
                                                        {status}
                                                    </div>
                                                </td>
                                                <td>
                                                    <a
                                                        href={a.account.website}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {a.account.website}
                                                    </a>
                                                </td>
                                                <td>{a.account.username}</td>
                                                <td>{a.provider.name}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    ) : isLoading ? (
                        <div className={styles.loading}>
                            <Loader
                                loading={isLoading}
                                size={48}
                                color={'var(--dark1)'}
                            />
                        </div>
                    ) : (
                        <p>No bettors active.</p>
                    )}
                </div>
            </div>
        </div>
    )
}
