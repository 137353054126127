export interface ProviderIF {
    id: number
    name: string
    scraperProviderId?: number
}

export interface LeagueIF {
    league: string
    sport: string
}

export interface OddsIF {
    id: number
    category: string
    group: string
    description: string
    line: number
    gameId: string
    metadata: {
        category: string
        group: string
        description: string
        time: string
    }
}

export interface GameIF {
    id: string
    sport: string
    league: string
    homeTeam: string
    awayTeam: string
    providerId: number
    provider: ProviderIF
}

export enum ACCOUNT_STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DISABLED_MAX_WIN = 'DISABLED_MAX_WIN',
    ERROR = 'ERROR',
    LOGIN_ERROR = 'LOGIN_ERROR',
    ARCHIVED = 'ARCHIVED',
}

export interface AccountProviderIF {
    providerId?: number
    accountId?: number
    id?: number
    account: AccountIF
    provider: ProviderIF
}

export interface AccountProviderScraperIF
    extends AccountProviderIF,
        ScraperStatusIF {}

export interface AccountIF {
    id: number
    username: string
    password: string
    website: string
    tags: string[]
    proxyCity?: string
    proxyState?: string
    accountProviders: AccountProviderIF[]
    autoBetAmount: number
    winTotal?: number
    liability?: number
    isScraperAccount: boolean
    source?: string
    status: ACCOUNT_STATUS
    activity: AccountActivityIF[]
    supportedBetTypes: string[]
    supportedBetIntervals: string[]
    supportedBetLeagues: string[]
    currency?: string
    family?: string
    subSource?: string
}
export interface AccountScraperIF extends AccountIF {
    accountProviders: AccountProviderScraperIF[]
}

export interface AccountActivityIF {
    activity: string
    createdAt: Date
}

export interface BetIF {
    id: number
    groupId: string
    createdAt: Date
    updatedAt: Date
    status: string
    account: AccountIF
    provider: ProviderIF
    odds: OddsIF & { game: GameIF }
    realOddsLine: number | null
    amount: number
    transactionCode: string
    errorCode: string
    errorReason: string
    userId: number
    user: { username: string }
    isAutobet: boolean
    outcome: string
    outcomeAmount: number
    riskAmount: number
}

export interface SettingsIF {
    scrapersActive: boolean
    bettorsActive: boolean
}

export interface ReportFilterIF {
    provider: string[]
    outcome: string[]
    status: string[]
    user: string[]
    source: string[]
    account: string[]
}

export interface ReportOverviewIF {
    unique: number
    attempted: number
    placed: number
    won: number
    void?: number
    lost: number
    amountWon: number
    amountVoided?: number
    amountLost: number
    net: number
    netLiable: number
    liability?: number
}

export interface ReportIF {
    bets: BetIF[]
    filters: ReportFilterIF | null
    totals: ReportOverviewIF
}

export enum DropletStatus {
    ACTIVE = 'ACTIVE',
    STARTING = 'STARTING',
    STOPPING = 'STOPPING',
}

export interface ScraperStatusIF {
    dropletId: string
    dropletStatus: DropletStatus | null
    healthchecksMissed: number
    scrapesMissed?: number
}

export type GameScraperIF = GameIF & ScraperStatusIF

export interface SettingsWHIF {
    scrapersActive: boolean
    bettorsActive: boolean
}

export interface LoginPayloadIF {
    token: string
    userId: number
    role: string
    email?: string
}

export interface NotificationIF {
    date: Date
    message: string
}

export enum UserRoles {
    ADMIN = 'ADMIN',
    BETTOR = 'BETTOR',
}

export enum RightSidebar {
    NOTIFICATION = 'NOTIFICATION',
    BET_HISTORY = 'BET_HISTORY',
}
